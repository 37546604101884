import React, { useState, useEffect } from 'react';
import './LessonDetails.css';
import { useLocation } from 'react-router-dom';

const LessonDetail = ({ user }) => {
  const { state } = useLocation();
  const lesson = state?.lesson;

  const [access, setAccess] = useState(false);

  // Set the access state based on user.access when the component loads
  useEffect(() => {
    console.log('user.access:', user?.access, 'Type:', typeof user?.access); // Log the value and type of user.access
    if (user?.access !== undefined) {
      setAccess(user.access); // Update the access state based on user.access
    }
  }, [user?.access]); // Watch for changes in user.access only

  console.log('Access state:', access, 'Type:', typeof access); // Log the value and type of access
  const booleanAccess = user.access === 'true';

  // Function to extract the embed link from a Google Drive URL
  const getGoogleDriveEmbedUrl = (link) => {
    if (link) {
      const fileId = link.split('/d/')[1]?.split('/')[0];
      return `https://drive.google.com/file/d/${fileId}/preview`;
    }
    return '';
  };

  // Handle restricted access
  if (!booleanAccess) {
    return (
      <div className="restricted-message">
        يرجى الاشتراك لعرض المحتوى الكامل
      </div>
    );
  }

  // Handle missing lesson data
  if (!lesson) {
    return (
      <div className="restricted-message">
        لا توجد بيانات عن الدرس المعروض
      </div>
    );
  }

  // Render the lesson details if access is true
  return (
    <div className="lesson-detail-container">
      {/* Lesson title */}
      <h1>{lesson.Nom || 'بدون عنوان'}</h1>

      {/* Lesson image */}
      {lesson.LienImage && (
        <div className="lesson-image">
          <h2>صورة الدرس</h2>
          <img
            src={getGoogleDriveEmbedUrl(lesson.LienImage)}
            alt="Lesson"
            style={{
              width: '100%',
              maxWidth: '640px',
              height: 'auto',
              marginBottom: '20px',
            }}
          />
        </div>
      )}

      {/* Lesson videos */}
      {lesson.LiensVideos?.length > 0 && (
        <div className="lesson-videos">
          <h2>الفيديوهات</h2>
          <div>
            {lesson.LiensVideos.map((videoUrl, index) => (
              <iframe
                key={index}
                title={`lesson-video-${index}`}
                src={getGoogleDriveEmbedUrl(videoUrl)}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{
                  width: '100%',
                  maxWidth: '640px',
                  height: 'auto',
                  marginBottom: '20px',
                }}
              ></iframe>
            ))}
          </div>
        </div>
      )}

      {/* Lesson presentation */}
      {lesson.LienDiapo && (
        <div className="lesson-powerpoint">
          <h2>العرض التقديمي</h2>
          <iframe
            title="lesson-presentation"
            src={getGoogleDriveEmbedUrl(lesson.LienDiapo)}
            frameBorder="0"
            allowFullScreen
            width="640"
            height="480"
            onError={(e) => {
              e.target.style.display = 'none'; // Hide the iframe if loading fails
            }}
            style={{ marginBottom: '20px' }}
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default LessonDetail;
